$white: #FFFFFF;
$darker: #1C2428;
$dark: #37474F;
$gray-light: #EBF1F4;
$gray-dark: #8B9EA7;
$gray-dark-light: #d3d8db;
$primary-color: #FF4081;
$green-color: #74C64A;
$failed-color: #E48755;
$pending-color: #E4C455;
$accent-color: #2196F3;
$purple-color: #653BB5;
$accent-color-2: #1AC673;
$error: #FF4100;
$primary-extra-light: #E2EEFF;
$primary-color-light: #FED8E4;
$green-color-light: #CBF6EC;
$green-color-dark: #29A76A;
$accent-color-light: #D9E9FF;
$text-warning-color: #FF7140;
$accent-color-lighter: #E4EEFB;

.bkg-darker {
  background-color: $darker;
}

.text-dark {
  color: $dark;
}

.bkg-dark {
  background-color: $dark;
}

.text-lighter {
  color: $gray-light;
}

.bkg-lighter {
  background-color: $gray-light;
}

.text-light {
  color: $gray-dark;
}

.bkg-light {
  background-color: $gray-dark;
}

.text-white {
  color: $white;
}

.bkg-white {
  background-color: $white;
}

.text-primary {
  color: $primary-color;
}

.bkg-primary {
  background-color: $primary-color;
}

.text-accent {
  color: $accent-color;
}

.bkg-accent {
  background-color: $accent-color;
}

.text-gray-dark {
  color: $gray-dark;
}

.text-gray-dark-light {
  color: $gray-dark-light;
}

.text-warning-color {
  color: $text-warning-color;
}
