.dhx_diagram_editor__sidebar{
    min-width:340px;
    max-width:340px;
}

.application-phone-node {
  position: relative;
  width: 220px;
  height: 100px;
  border-top: solid 5px;
  border-radius: 3px;
  font-family: sans-serif;
  line-height: 1;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  transition: all 0.3s cubic-bezier(.25,.8,.25,1);
  background-color: #fff;
}

  .application-phone-node:hover {
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
  }

  .application-phone-node .node-description-title {
    /* font-size:10px;
      margin-bottom:0;
      padding-top:5px;
      color:#000;*/

    font-size: 80%;
    margin-bottom: 0;
    background-color: #000;
    color: #ffff;
    padding: 3px 7px;
    max-width: 90%;
    border-radius: 3px;
    text-overflow: clip;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-weight:bold;
    line-height:1;
  }

  .application-phone-node > h2,
  .application-phone-node-description {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .application-phone-node > h2 {
    font-size: 0.85rem;
    text-transform: uppercase;
    margin: 0;
    padding: 0;
    justify-content: center;
    height: 32px;
    color: #fff;
    column-gap: 10px;
    border-bottom: solid 1px;
    font-weight: bold;
    line-height: 1;
  }

    .application-phone-node > h2 > svg {
      width: 25px;
    }

  .application-phone-node.trunkdial > h2 > svg,
  .application-phone-node.goto > h2 > svg,
  .application-phone-node.whatsapp > h2 > svg,
  .application-phone-node.askgenerativeai > h2 > svg {
    width: 17px;
  }

  .application-phone-node.email > h2 > svg {
    width: 35px;
  }
  .application-phone-node.sms > h2 > svg {
    width: 30px;
  }
  .application-phone-node.condition > h2 > svg, 
  .application-phone-node.setsessionvariable > h2 > svg {
    width: 20px;
  }

    .application-phone-node.say {
      border-color: #7fddb5;
    }

    .application-phone-node.say h2 {
      color: #7fddb5;
    }

      .application-phone-node.say .node-description-title {
        background-color: #7fddb5;
      }

  .application-phone-node.answer {
    border-color: #f76549;
  }

    .application-phone-node.answer .node-description-title {
      background-color: #f76549;
    }

    .application-phone-node.answer h2 {
      color: #f76549;
    }

  .application-phone-node.play {
    border-color: #7fddb5;
  }

    .application-phone-node.play .node-description-title {
      background-color: #7fddb5;
    }

    .application-phone-node.play h2 {
      color: #7fddb5;
    }

  .application-phone-node.menu {
    border-color: #6a41c5;
  }

    .application-phone-node.menu .node-description-title {
      background-color: #6a41c5;
    }

    .application-phone-node.menu h2 {
      color: #6a41c5;
    }

  .application-phone-node.dial {
    border-color: #f7481c;
  }

    .application-phone-node.dial .node-description-title {
      background-color: #f7481c;
    }

    .application-phone-node.dial h2 {
      color: #f7481c;
    }

  .application-phone-node.trunkdial {
    border-color: #f7481c;
  }

    .application-phone-node.trunkdial .node-description-title {
      background-color: #f7481c;
    }

    .application-phone-node.trunkdial h2 {
      color: #f7481c;
    }

  .application-phone-node.record,
  .application-phone-node.detectlanguage {
    border-color: #007bcb;
  }

    .application-phone-node.record .node-description-title,
    .application-phone-node.detectlanguage .node-description-title {
      background-color: #007bcb;
    }

    .application-phone-node.record h2,
    .application-phone-node.detectlanguage h2 {
      color: #007bcb;
    }

  .application-phone-node.collect {
    border-color: #9cd2eb;
  }

    .application-phone-node.collect .node-description-title {
      background-color: #9cd2eb;
    }

    .application-phone-node.collect h2 {
      color: #9cd2eb;
    }

  .application-phone-node.collectspeech {
    border-color: #9dd1ea;
  }

    .application-phone-node.collectspeech .node-description-title {
      background-color: #9dd1ea;
    }

    .application-phone-node.collectspeech h2 {
      color: #9dd1ea;
    }

  .application-phone-node.httprequest {
    border-color: #f2644b;
  }

    .application-phone-node.httprequest .node-description-title {
      background-color: #f2644b;
    }

    .application-phone-node.httprequest h2 {
      color: #f2644b;
    }

  .application-phone-node.conference,
  .application-phone-node.agentconference,
  .application-phone-node.agentphone,
  .application-phone-node.agentgroup,
  .application-phone-node.remoteagent {
    border-color: #54d2c4;
  }

    .application-phone-node.conference .node-description-title,
    .application-phone-node.agentconference .node-description-title,
    .application-phone-node.agentphone .node-description-title,
    .application-phone-node.agentgroup .node-description-title,
    .application-phone-node.remoteagent .node-description-title {
      background-color: #54d2c4;
    }

    .application-phone-node.conference h2,
    .application-phone-node.agentconference h2,
    .application-phone-node.agentphone h2,
    .application-phone-node.agentgroup h2,
    .application-phone-node.remoteagent h2 {
      color: #54d2c4;
    }

  .application-phone-node.redirect,
  .application-phone-node.appendnodes {
    border-color: #f7481c;
  }

    .application-phone-node.redirect .node-description-title,
    .application-phone-node.goto .node-description-title,
    .application-phone-node.appendnodes .node-description-title {
      background-color: #f7481c;
    }

    .application-phone-node.redirect h2,
    .application-phone-node.appendnodes h2 {
      color: #f7481c;
    }

  .application-phone-node.goto {
    border-color: #f7481c;
  }

    .application-phone-node.goto h2 {
      color: #f7481c;
    }

  .application-phone-node.silence {
    border-color: #7fddb5;
  }

    .application-phone-node.silence .node-description-title {
      background-color: #7fddb5;
    }

    .application-phone-node.silence h2 {
      color: #7fddb5;
    }

  .application-phone-node.storage {
    border-color: #007BCB;
  }

    .application-phone-node.storage .node-description-title {
      background-color: #007BCB;
    }

    .application-phone-node.storage h2 {
      color: #007BCB;
    }

    .application-phone-node.storage svg {
      margin-top: 5px;
    }

  .application-phone-node.setsessionvariable {
    border-color: #e1a825;
  }

    .application-phone-node.setsessionvariable .node-description-title,
    .application-phone-node.condition .node-description-title {
      background-color: #e1a825;
    } 

    .application-phone-node.setsessionvariable h2 {
      color: #e1a825;
    }

  .application-phone-node.condition {
    border-color: #e1a825;
  }

    .application-phone-node.condition h2 {
      color: #e1a825;
    }

  .application-phone-node.whatsapp,
  .application-phone-node.email,
  .application-phone-node.sms {
    border-color: #FF4081;
  }

    .application-phone-node.whatsapp h2,
    .application-phone-node.email h2,
    .application-phone-node.sms h2 {
      color: #FF4081;
    }

.application-phone-node-description {
  padding:10px;
  font-size: 12px;
  line-height: 1.35;
  color: #535353;
  align-items: center;
  height: calc(100% - 40px);
  text-align: center;
  justify-content: center;
  word-break: break-word;
}

  .application-phone-node-description i {
    color: #909090;
    word-break: normal;
  }

.application-phone-node.askaiassistant,
.application-phone-node.askgenerativeai {
  border-color: #1976D2;
}

.application-phone-node.askaiassistant .node-description-title,
.application-phone-node.askgenerativeai .node-description-title {
  background-color: #1976D2;
}

  .application-phone-node.askaiassistant h2,
  .application-phone-node.askgenerativeai h2 {
    color: #1976D2;
  }

.dhx_diagram_item.dhx_diagram_shape.dhx_diagram_flow_item.dhx_selected,
.dhx_diagram_item.dhx_diagram_shape.dhx_diagram_flow_item.dhx_selected > div {
  width: 220px !important;
  height: 100px !important;
}

.dhx_diagram_line_title {
  font-size: 14px !important;
}
