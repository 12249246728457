/*
  =================================================================================================
  general layout variables
  =================================================================================================
*/
$header-height: 60px;
$bot-header-height: 60px;
$sidenav-modules-width: 5rem; /* main menu that contains the modules: console, sms, email, etc */
$sidenav-menu-width: 11rem; /* secondary menu that contains the menu for each module */
$live-agent-sidenav-menu-width: 25rem; /* secondary menu on live-agent */
$sidenav-container-padding: 1rem 0rem;

$breakpoint-tablet: 768px;
$breakpoint-laptop: 1024px;
$breakpoint-large: 1600px;
$scrollbar-width:5px;

