@use '@angular/material' as mat;

@font-face {
  font-family: 'Sofia Pro Regular';
  src: url('../../../wwwroot/css/fonts/SofiaPro-Regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('../../../wwwroot/css/fonts/SofiaPro-Regular.woff') format('woff'), /* Pretty Modern Browsers */
  url('../../../wwwroot/css/fonts/SofiaPro-Regular.otf') format('opentype');
}
// Define a custom typography config that overrides the font-family
$custom-typography: mat.define-typography-config($font-family: '"Sofia Pro Regular", sans-serif' );
@include mat.typography-hierarchy($custom-typography);

/* You can add global styles to this file, and also import other style files */
@import './management/_variables.scss';
@import './management/_color-scheme';
@import "~jsoneditor/dist/jsoneditor.min.css";


:root {
  --mat-form-field-container-text-size: 14px;
  --mat-option-label-text-size: 15px;
  --mdc-icon-button-state-layer-size: 35px;
  --mdc-filled-text-field-label-text-size: 14px;
  --mat-table-row-item-container-height: 48px;
  --mdc-icon-button-state-layer-size: 35px;
  --mat-form-field-container-vertical-padding: 5px;
  --mat-form-field-container-height: 40px;
}



html, body {
  height: 100%;
  padding: 0;
  margin: 0;
  line-height: 1.65;
  font-size: 1rem;
  font-family: 'Sofia Pro Regular', sans-serif;
  font-weight: 400;
}

.dhx_diagram_editor__dropdown .node-description-title {
    display:none !important;
}
/*header {
  height: $header-height;
}*/
h1, h2, h3, h4, h5, h6,
b, strong,
.font-bold {
  font-weight: 700;
}

.font-extrabold {
  font-weight: 900;
}

.font-light {
  font-weight: 300;
}

.font-normal {
  font-weight: 400;
}

.text-italic {
  font-style: italic;
}

.property-item .mat-mdc-checkbox .mdc-form-field {
  font-size: 12px !important;
}

a,
a:link,
a:hover,
a:visited,
a:active,
a:focus {
  text-decoration: none;
  cursor: pointer;
  display: inline-flex;
  position: relative;
  justify-content: center;
  align-items: center;
}

a, button, input
.transition {
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.link-not-href {
  color: $accent-color !important;
  cursor: pointer;
}

.full-height {
  height: 100%;
}

.full-width {
  width: 100%;
}

.height-50 {
  height: 50%;
}

*[class^='mat'] {
  font-family: 'Sofia Pro Regular', sans-serif;
}

.text-underline {
  text-decoration: underline;
}

.mat-horizontal-stepper-header-container {
  pointer-events: none !important;
}

.undefined-list {
  color: darkgray;
  font-size: 1.5rem;
  font-weight: 400;
  text-align: center;
}

.text-center {
  text-align: center;
}

.width-50 {
  width: 50% !important;
}

.logs-dialog-container .mat-dialog-container {
  transform: translateX($sidenav-modules-width+3rem) translateY($header-height) !important;
}

.text-warning-color {
  color: $text-warning-color;
}

.mg-top1rem {
  margin-top: 1rem;
}

.mg-top2rem {
  margin-top: 2rem;
}

.cursorOff {
  cursor: auto !important;
}

.mg-btm1rem {
  margin-bottom: 1.5rem;
}

.mg-left1rem {
  margin-left: 1rem;
}

.mat-dialog-title {
  font-family: 'Sofia Pro Regular', sans-serif !important;
}

.mat-mdc-card {
  font-family: 'Sofia Pro Regular', sans-serif !important;
}

.login-nav-menu.mat-mdc-menu-panel {
  max-width: none !important;
}

//credit Pop=up
.credit-pop-up {
  margin-top: 40px;
  margin-bottom: 30px;
  color: $primary-color;
}

.credit-pop-up {
  h4 {
    line-height: 30px;
  }

  h2 {
    font-size: 70px;
  }

  p {
    margin-bottom: 0;
  }
}

.w-70 {
  width: 70% !important;
}

.w-30 {
  width: 30% !important;
}

.btns-container {
  position: fixed;
  bottom: 0;
  width: 70%;
  z-index:999;
}

.no-margin {
  margin: 0;
}

.asterisk-tab .mat-tab-body-content {
  overflow: hidden !important;
}


.primary-color {
  color: $primary-color;
}

.accent-color {
  color: $accent-color;
}

.green-color {
  color: $green-color;
}

.gray-color {
  color: $gray-dark;
}


.gray-background {
  background-color: $gray-dark;
  color: #ffff;
}

#app-phone-stepper, #app-email-stepper, #app-sms-stepper {
  .mat-horizontal-stepper-header-container {
    display: none !important;
  }
}

.manage-did {
  border-radius: 30px;
  border: 1px solid #FF4081;
  color: $primary-color;
  font-size: 0.65rem;
  padding: 0.50em 0.7rem;
  line-height: 1;
  margin-bottom: 1rem;

  &:hover, &:active, &focus {
    cursor: pointer;
    color: #fff;
    background-color: $primary-color;
  }
}

.hide-tabs {
  > .mat-mdc-tab-header {
    display: none;
  }
}

// BEGIN Diagram Editor
.element-template {
  box-sizing: content-box;
  width: 150px;
  height: 150px;
  padding: 30px;
  border: 1px solid blue;
  font-size: 12px;
}


.title-shape {
  text-align: center;
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 1rem;
}

.dhx_icon[data-dhx-id="addLineText"],
.dhx_icon[data-dhx-id="lineTextAutoPositionDisable"] + .dhx_icon.dhx_icon_remove,
.dhx_icon[data-dhx-id="lineTextAutoPositionEnable"] + .dhx_icon.dhx_icon_remove {
  display: none !important;
}

/*.dhx_shape_thumb:hover svg path {
  fill: #ed1e79 !important;
  stroke: #ed1e79 !important;
}*/


/*.dhx_layout-cell {
  width: 200px !important;
  flex-basis: 200px !important;
}*/

// END Diagram Editor

.flat-rate-tabs {
  overflow: hidden !important;

  mat-tab-body {
    overflow: hidden !important;

    .mat-tab-body-content {
      overflow: hidden !important;
    }
  }
}

.renew-did-mat-select {
  .mat-select-value {
    .mat-select-value-text {
      vertical-align: sub;
      color: #ffffff;
    }
  }
}

.inner-layout-container h1 {
  font-size: 1.6rem;
  font-weight: 700;
}

.mat-button-wrapper .mat-icon {
  font-family: 'Material Icons' !important;
}

.did-icon {
  background-image: url("../assets/img/did-icon.png");
  width: 100%;
  height: 25px;
  background-repeat: no-repeat;
  margin-left: 1rem;
}

textarea.jsoneditor-text {
  min-height: 350px !important;
}

.ace_editor.ace-jsoneditor {
  min-height: 350px !important;
}


.beta-badge {
  display: inline-block;
  line-height: 1;
  color: $white;
  background-color: $primary-color;
  text-transform: uppercase;
  font-size: 0.45rem;
  font-weight: 700;
  padding: 0.2rem 0.3rem;
  border-radius: 3px;
  position: absolute;
  top: -3px;
  right: 7px;
}

audio::-webkit-media-controls-mute-button {
  display: none !important;
}

audio::-webkit-media-controls-volume-slider {
  display: none !important;
}

/* Removes the timeline */
audio::-webkit-media-controls-timeline {
  display: none !important;
}

audio::-webkit-media-controls-current-time-display {
  font-size: 12px !important;
}

audio::-webkit-media-controls-time-remaining-display {
  font-size: 12px !important;
}

.agent-activity-calls-tooltip {
  white-space: pre-line;
}

/*.input-agent {
  .mat-form-field-label-wrapper {
    margin-left: -10px;
    width:110%;
  }
}*/

.agent-panel, .chat-tab-container {
  .mat-tab-body-content {
    overflow: hidden !important;
  }
}

.chat-tab-container {
  .mat-tab-label-content {
    text-transform: uppercase;
    font-size: 0.75rem;
    letter-spacing: 0.035rem;
    word-spacing: 0.05rem;
  }

  .mat-tab-label-active {
    font-weight: 700;
  }
}

.form-field-info {
  margin-top: -15px;
}

.info-circle {
  border-radius: 50%;
  color: #74C64A;
  border: 2px solid #74C64A;
  height: 1.1rem;
  width: 1.1rem;
  padding-top: -10px;
  font-size: 0.55rem;
  display: inline-block;
  line-height: 1rem;
  text-align: center;
  margin-right: 5px;
}

.question-circle {
  display: inline-block;
  line-height: 1;
  color: $gray-dark;
  text-transform: uppercase;
  font-size: 0.75rem;
  font-weight: 700;
  padding: 0.2rem 0.3rem;
  border-radius: 3px;
  position: absolute;
  top: -3px;
}

.mat-column-AllRequests {
  mat-progress-bar {
    width: 70%;
  }
}

.campaign-status {
  height: 100%;
  border-left-style: solid;
  border-left-width: 10px;

  &.failed {
    border-left-color: $failed-color;
  }

  &.success {
    border-left-color: $accent-color-2;
  }

  &.pending {
    border-left-color: $pending-color;
  }
}


/*.campaign-status::before {
  content: "";
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  width: 3px;
  height: 75%;
  bottom: 0;
  margin: auto;
}

.campaign-status.success::before {
  background-color: $accent-color-2;
}

.campaign-status.failed::before {
  background-color: $failed-color;
}

.campaign-status.pending::before {
  background-color: $pending-color;
}*/

.mat-button-wrapper {
  .app-icon-btn {
    font-size: 13px;
    margin-bottom: 0.3rem;
  }
}

.align-center {
  display: flex;
  align-items: center;
}

.align-end {
  text-align: end;
}


.highlight-text {
  background-color: $primary-extra-light;
  position: relative;
  padding-left: 5px;
  padding-right: 5px;
  /* padding: 2px; */
  margin: 15px 0px 25px;
  /*-webkit-user-select: none;*/ /*Safari */
  /*-moz-user-select: none;*/ /*/ Firefox /*/
  /*-ms-user-select: none;*/ /*/ IE10+/Edge /*/
  /*user-select: none;*/ /*/ Standard /*/
}

.highlight-text::after {
  content: attr(data-entity);
  position: absolute;
  left: 0;
  top: 100%;
  font-size: 11px;
  width: 100%;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.highlight-text .tooltiptext {
  visibility: hidden;
  white-space: nowrap;
  padding: 0 10px;
  position: absolute;
  bottom: calc(100% + 5px);
  left: 0;
  background: #000;
  color: #fff;
  border-radius: 3px;
  font-size: 12px;
}

.tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 15px;
  width: 0;
  margin: auto;
  height: 0;
  border-left: solid 5px #fff;
  border-top: solid 5px #000;
  border-right: solid 5px #fff;
  border-bottom: solid 1px #fff;
}

.highlight-text:hover .tooltiptext {
  visibility: visible;
}

.highlight-text-subentity {
  background-color: #fe8888;
  position: relative;
  padding-left: 5px;
  padding-right: 5px;
  /* padding: 2px; */
  margin: 15px 0px 25px;
  /*-webkit-user-select: none;*/ /*Safari */
  /*-moz-user-select: none;*/ /*/ Firefox /*/
  /*-ms-user-select: none;*/ /*/ IE10+/Edge /*/
  /*user-select: none;*/ /*/ Standard /*/
}

.highlight-text-subentity::after {
  content: attr(data-entity);
  position: absolute;
  left: 0;
  top: 100%;
  font-size: 11px;
  width: 100%;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.highlight-text-subentity .tooltiptext {
  visibility: hidden;
  white-space: nowrap;
  padding: 5px 10px;
  position: absolute;
  bottom: calc(100% + 5px);
  left: 0;
  background: #000;
  color: #fff;
  border-radius: 3px;
  font-size: 12px;
}

.tooltiptext-subentity::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 15px;
  width: 0;
  margin: auto;
  height: 0;
  border-left: solid 5px #fff;
  border-top: solid 5px #000;
  border-right: solid 5px #fff;
  border-bottom: solid 5px #fff;
}

.highlight-text-subentity:hover .tooltiptext {
  visibility: visible;
  z-index: 1000;
}

.parent-entity {
  display: inline-block;
  position: relative;
}

.parent-entity::before {
  content: attr(data-parent);
  position: absolute;
  top: -0.8rem;
  left: 0;
  width: 100%;
  padding: 5px 7px;
  border-bottom: solid 2px $accent-color;
  text-align: center;
  z-index: 999;
  line-height: 1;
  color: $accent-color;
  font-size: 11px;
  font-weight: bold;
}

.utterance-text {
  padding: 13px 0 8px 0;
}

.bot-entities-tab {
  .mat-mdc-tab-header-pagination-controls-enabled .mat-mdc-tab-header-pagination {
    display: none;
  }

  .mat-tab-body-content {
    overflow-x: hidden;
  }
}

.export-btn {
  button {
    float: right;
  }
}

.avaible-routes {
  .mat-list-text {
    white-space: nowrap;

    b {
      font-weight: 700 !important;
    }
  }

  .mat-list-base .mat-list-item, .mat-list-base .mat-list-option {
    display: inline-flex;
    white-space: nowrap;
  }

  .mat-list-base .mat-list-item .mat-list-item-content-reverse, .mat-list-base .mat-list-option .mat-list-item-content-reverse {
    flex-direction: row;
  }

  .mat-pseudo-checkbox {
    margin-right: 10px;
  }

  .mat-list-base .mat-list-item .mat-list-text, .mat-list-base .mat-list-option .mat-list-text {
    flex-direction: inherit;
    white-space: nowrap;
  }
}

.fixed-header {
  position: fixed;
  z-index: 997;
  padding-left: $sidenav-menu-width + $sidenav-modules-width;
  width: 100%;
  left: 0;
  top: $header-height;
  background-color: #ffff;

  .fixed-header-container {
    padding: 1rem 2.5rem;
    line-height: 1;
  }

  h2 {
    font-size: 1.25rem;
  }
}

.radius-button {
  border-radius: 25px !important;
  background-color: $gray-light;
  color: #000;
}

.mat-slide-toggle-content {
  font-size: 20px;
  color: rgba(0,0,0,.54);
}

.bot-trigger-button {
  border-radius: 25px !important;
  background-color: $gray-light;
}


.open-sans {
  font-family: 'Open Sans', sans-serif;
}

.raleway {
  font-family: 'Raleway', sans-serif;
}

.roboto {
  font-family: 'Roboto', sans-serif;
}

.montserrat {
  font-family: 'Montserrat', sans-serif;
}

.poppins {
  font-family: 'Poppins', sans-serif;
}

.merriweather {
  font-family: 'Merriweather', serif;
}

.noto-serif {
  font-family: 'Noto Serif', serif;
}

.rasa {
  font-family: 'Rasa', serif;
}

.dhx_connect_point:first-child {
  display: none !important;
}

/*live agent notification styles*/
.live-agent-notification {
  background-color: #ffff;
  padding: 1rem !important;
  border-left: 10px solid $green-color-dark;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24) !important;
  font-size: 0.9rem !important;
  line-height: 1.3 !important;
  color: #000 !important;

  &.team {
    border-color: $green-color-dark;
  }

  &.new-task {
    border-color: $primary-color;
  }

  &.new-task-message {
    border-color: $accent-color;
  }

  &.invite {
    border-color: $pending-color;
  }

  &.transfer {
    border-color: $failed-color;
  }
}
/* end live agent notification styles*/

/*new styles from component after upgrade*/
mat-card-content.credit-usage-card {
  hr {
    margin-top: 0;
    margin-bottom: 0;
  }

  .mat-list {
    position: relative;

    .mat-list-item {
      font-size: 1.1rem;
    }
  }
}

.mdc-text-field--filled:not(.mdc-text-field--disabled), .mat-mdc-form-field-focus-overlay {
  background-color: transparent !important;
}

.mat-mdc-tab-header {
  border-bottom: 1px solid rgba(0,0,0,.12);
}

.mdc-text-field--no-label .mat-mdc-form-field-infix {
  padding-bottom: 0 !important;
}

.mat-mdc-card {
  padding: 16px;

  .mat-mdc-card-content {
    padding: 0;
  }
}

mat-tab-body .mat-mdc-tab-body-content {
  overflow: hidden;
}

.mat-mdc-icon-button.mat-mdc-button-base, .mat-mdc-icon-button .mat-mdc-button-touch-target {
  width: 35px;
  height: 35px;
  font-size: 1rem;
  line-height: 1 !important;
  padding: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  float: left;
}

.mat-mdc-icon-button {
  font-size: 14px;
}

.mat-mdc-dialog-surface {
  padding: 24px !important;
}

.mat-mdc-dialog-component-host {
  display: block !important;
}

h1.mdc-dialog__title {
  padding: 0;
}

.mat-mdc-text-field-wrapper.mdc-text-field {
  padding: 0;
}

label.mdc-label {
  margin-bottom: 0;
}

.mat-mdc-dialog-content {
  overflow-x: hidden !important;
  padding: 5px 5px 5px 0px !important;
}

.mdc-data-table__header-cell {
  overflow: visible !important;
}


.mat-tab-content-overflow {
  .mat-mdc-tab-body-content {
    overflow-y: auto;
  }
}

.mat-mdc-option{
    padding:0 10px !important;
}

.material-icons {
  font-family: 'Material Icons' !important;
}

.margin-buttom-3 {
  margin-bottom: 3px;
}

.mat-mdc-form-field-icon-prefix span {
  white-space: nowrap;
  flex: none;
  position: relative;
}

.filter-form-operator {
  font-size: 12px !important;
  min-height: 20px !important;
  padding: 5px 10px !important;
  line-height: 20px !important;
}

.filter-grid-container-form form {

  .mat-mdc-form-field-subscript-wrapper {
    line-height: 5px !important;
    height: 5px !important;
  }

  .mat-mdc-select-value-text {
    font-size: 13px;
  }

  .mat-mdc-form-field-infix {
    padding-bottom: 5px !important;
    padding-top: 15px !important;
  }

  mat-select {
    line-height: 15px;
  }
}

.custom-select-padding-buttom {
  .mat-mdc-form-field-infix {
    padding: 10px 0 0 0 !important;
  }

  mat-label {
    line-height: 22px;
  }
}

.custom-select-padding {
  .mat-mdc-form-field-infix {
    padding: 15px 0 3px 0 !important;
  }

  mat-select {
    line-height: 15px;
    font-size: 14px;
  }
}

.mat-sort-header-content{
    font-size:13px;
}

mat-radio-group {
  overflow: hidden !important;
}

.web-design-select {
  .mat-mdc-select-min-line {
    padding-right: 0.3rem;
  }

  .mat-mdc-select-arrow-wrapper {
    margin-bottom: 0.4rem;
  }
}

div.mat-mdc-form-field-error-wrapper {
  padding: 0 !important;
}

.bs-timepicker-field{
    width:50px !important;
}

.custom-mat-select {
  mat-select {
    line-height: 15px;
    font-size: 14px;
  }
}

.mdc-text-field--filled.mdc-text-field--disabled{
    background-color:transparent !important;
}

mat-card-actions{
    .row{
        width:100%;
    }
}

button.mdc-fab--mini {
  width: 30px;
  height: 30px;
  font-size: 14px;
  box-shadow:none;
}

.debug-mat-tab .mat-mdc-tab-header {
  border-bottom: none !important;
}

.widget-box {

  .dhx_diagram.dhx_widget {
    background: white !important;

    .dhx_diagram__container.dhx_free_diagram {
      width: 100% !important;
    }
  }
}

.mat-mdc-form-field-hint-wrapper{
    padding-left:0 !important;
}

div.editor-input,
textarea.editor-input,
select.editor-input,
input[type="text"].editor-input,
input[type="number"].editor-input {
  padding: 5px 8px;
  border-radius: 4px;
  border-color: #aaa;
  font-size: 13px;
  line-height: 1.5;
  border-width: 1px;
  min-height: 33px;
  box-shadow: none;
  outline: none;
  margin-bottom: 0.35rem;
}

.phone-editor-checkbox {
  left: -12px!important;
  top:-2px
}

.custom-tag-highlighted {
  background-color: rgba(0, 123, 203, 0.7);
  color: #ffffff;
  font-size: 85%;
  line-height: 1;
  padding: 4px 6px;
  border-radius: 3px;
  letter-spacing: -0.02rem;
  margin: 2px;
  font-family: 'Sofia Pro Regular', sans-serif !important;
}

.session-tag {
  background-color: rgba(255, 64, 129, 0.7);
}

.tag-not-in-list {
  background-color: $gray-light;
  color: #000;
}

.node-tag-color {
  background-color: rgba(0, 123, 203, 0.7);
  color: #ffffff;
}

.mat-mdc-dialog-content.dialog-content-custom-callback-body {
  max-height: 50vh;
}

@media (min-width: $breakpoint-tablet) and (max-width:$breakpoint-laptop) {
  h1 {
    font-size: 1.7rem;

    .ng-star-inserted {
      font-size: 1.7rem;
    }
  }

  .inner-layout-container h1 {
    font-size: 1.5rem;
  }


  h2 {
    font-size: 1.5rem;
  }

  h3 {
    font-size: 1.3rem;
  }

  h4 {
    font-size: 1.1rem;
  }

  .shapesbar_cont .dhx_shapesbar--with-width {
    display: none;
  }

  .ace_editor.ace-jsoneditor {
    min-height: 250px !important;
  }

  html, body {
    font-size: 0.9rem;
  }

  .mat-cell, .mat-footer-cell {
    font-size: 12px;
  }
}


header .mat-slide-toggle-bar {
  background-color: rgba(255, 255, 255, .38) !important;
}

header .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: $green-color-light !important;
}

header .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: $green-color-dark !important;
}

.mat-tooltip.new-task-tooltip {
  font-size: 14px !important;
  line-height: 1.2 !important;
}
/* begin whatsapp message styling */

.media-message,
.location-message {
  display: flex !important;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  cursor: pointer;
  color: $white;

  &:hover,
  &:active {
    color: $gray-light;
  }
}

.media-message {
  & figure {
    margin: 1rem 2rem;
    text-align: center;
  }

  & h5 {
    margin-bottom: 0.5rem;
    padding: 0;
  }

  & .document-icon {
    font-size: 4rem;
    margin-bottom: 0.5rem;
  }
}

.location-message {
  & a {
    font-size: 3.5rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    min-width: 250px;
    height: 150px;
    text-align: center;
    justify-content: center;
    align-items: center;
    background-color: $gray-dark-light;
    color: $gray-dark;
    cursor: pointer;

    &:hover {
      cursor: pointer;
      color: $accent-color;
    }
  }
}
/* end whatsapp message styling */

.survey-custom-tooltip {
  max-width: unset !important;
}

.survey-custom-tooltip .mdc-tooltip__surface {
  max-width: 450px !important;
}

.translate-icon {
  zoom: 0.75;
}
